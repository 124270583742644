._3t6nxS57YUmxn7A_3N1gk3 {
  margin-top: 15px; }

._34RzKbEbtZn2bNFwJlmgyp ._1AeVITJ6YFbByuCZmpG44J:not(:first-child) {
  margin-top: 20px; }

._3UpQ-Co443Uvx82hOYU3wY,
._2odYbOmPNNSQYLqV18peQv,
._1Xm1j47xCdmnMv6n8dUy1G {
  display: none; }

.dfUZrV6ZwxPvDwdyNS6s_ {
  display: none; }

._34RzKbEbtZn2bNFwJlmgyp,
._2nTsCcYXXq6A_PrgIfQ9Nh {
  margin-left: 15px;
  margin-bottom: 15px; }

._2n9jzrq7Pp4MuF0gxr0gFW {
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between; }

.tbFLlJJgwrVCMYGA9xMHD {
  margin-left: 15px;
  line-height: 28px; }
  .tbFLlJJgwrVCMYGA9xMHD a {
    cursor: pointer;
    color: black;
    text-decoration: none;
    word-wrap: break-word; }
    .tbFLlJJgwrVCMYGA9xMHD a:hover {
      color: #0087b0;
      text-decoration: none; }

.SVubCQa4LfB16gA5Lh_Pd {
  margin-left: 0;
  line-height: 28px; }
  .SVubCQa4LfB16gA5Lh_Pd a {
    cursor: pointer;
    color: black;
    text-decoration: none;
    word-wrap: break-word; }
    .SVubCQa4LfB16gA5Lh_Pd a:hover {
      color: #0087b0;
      text-decoration: none; }

._1hLB1BRJow-XqMt9EqvniR {
  display: block; }

._2M3_2wixk6gSFtBY-FPGOi {
  display: none; }

._31t7k5lTwadxa2kl_Xzsn3 {
  cursor: pointer;
  text-align: left;
  text-decoration: underline;
  color: #337ca3;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.9rem; }

.LlEXlOGvZt5zjlXbRvuEh {
  margin-left: 5px;
  text-transform: capitalize; }

._2WNO9sdvlK0MlVnr9_3vDF {
  display: flex;
  justify-content: left; }

._3pNEwxv3v_wsLbPI9cy361 {
  text-transform: capitalize; }

._2ml63mIu3tQJlIfb8TzXi5 {
  width: 20px;
  height: 20px;
  min-width: 30px;
  margin: 0;
  margin-right: 10px;
  margin-top: 3.5px;
  cursor: pointer; }

._2r0AixujwcfXLAzLcOaHXs a {
  cursor: pointer;
  color: #337ca3;
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold; }

.FMkmu1OvZ87RidVSTl2Zy {
  color: white;
  background-color: #007ea8;
  border-radius: 6px;
  width: fit-content;
  margin-right: 12px;
  padding: 14px;
  position: relative;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  height: 2rem;
  white-space: nowrap;
  justify-content: space-around; }

._17x8f83lhcwXr2aF0Pxta3 {
  width: 14px;
  cursor: pointer;
  padding-top: 2px;
  margin-left: 10px;
  margin-right: 10px; }

@media (max-width: 767px) {
  ._2r0AixujwcfXLAzLcOaHXs {
    margin-bottom: 10px;
    text-align: right; }
  .dfUZrV6ZwxPvDwdyNS6s_ {
    position: absolute;
    right: 15px;
    cursor: pointer;
    display: none; }
  ._2v0cbUrQZv_Kwu0zKY4cvs,
  ._1_NYBO1WFayAqhmSV2x9mc {
    display: inline; }
  ._2nXyt0AIvDf0m-E0AdevV9,
  ._32H4piuE1iPomYLtZqL82A {
    display: inline; }
  ._3UpQ-Co443Uvx82hOYU3wY {
    position: absolute;
    right: 15px;
    cursor: pointer; }
  ._34RzKbEbtZn2bNFwJlmgyp,
  ._1Xm1j47xCdmnMv6n8dUy1G {
    display: none; }
  ._1dXvS3XE_-7853Evg_ZOVI {
    display: inline; }
  ._2odYbOmPNNSQYLqV18peQv {
    display: block; }
  ._3t6nxS57YUmxn7A_3N1gk3 {
    display: none; }
  ._2n9jzrq7Pp4MuF0gxr0gFW {
    padding-top: 5px;
    margin-top: 5px; }
  ._17x8f83lhcwXr2aF0Pxta3 {
    right: 2px; } }

._2dYTmXjAdluNy12JNJIimm {
  width: 14px; }

.UADJB375FpWHmDTkuKze {
  display: none; }

._3dGe1vighrAXuoIPA82lYz {
  display: none; }

@media (max-width: 767px) {
  ._2TuRA5V527zYYKOpHaYfmg {
    display: block;
    background-color: #fff;
    padding: 10px 10px;
    border-style: solid;
    border-color: #000;
    border-width: thin;
    font-size: 0.8rem;
    height: 43px;
    width: 47%; }
  ._1jjTLVidamhHFm29egYbQa {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: auto;
    justify-content: space-between; }
  ._2nTsCcYXXq6A_PrgIfQ9Nh {
    display: none; }
  ._38XOhK-AMm2jCRkekL167V {
    margin-bottom: 15px; }
  ._3aO2n6N-V48C4aHV6MfN4 {
    position: sticky;
    bottom: 0;
    background-color: #000;
    padding: 10px 10px;
    border-style: solid;
    border-color: #000;
    border-width: thin;
    color: #fff;
    text-align: center;
    font-size: 0.9rem;
    height: 45px;
    width: 100%;
    z-index: 50000; }
  .UADJB375FpWHmDTkuKze {
    display: flex;
    justify-content: space-between; }
  ._3dGe1vighrAXuoIPA82lYz {
    display: flex;
    position: fixed;
    top: 0;
    justify-content: space-around;
    align-items: center;
    z-index: 4998;
    width: 100%;
    background-color: #fff;
    height: 65px;
    left: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Adjust the values as needed */
    padding-left: 15px;
    padding-right: 15px; }
    ._3dGe1vighrAXuoIPA82lYz ._2TuRA5V527zYYKOpHaYfmg {
      width: 45%; } }

._21FpmndnHJPEHTs5uKzUA_ {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline; }

._1PLLl0lm3h89zEOiQ6XAni {
  display: flex;
  overflow-x: scroll; }

.OQvc-ipAbKnZJhSWiHTMK {
  display: flex;
  margin-bottom: 20px; }

._3NxbtSccJ4bD4uF2TenhRP {
  display: flex;
  flex-direction: column;
  font-size: 14px; }

.mQCxviwROb5diEB1MofOg {
  color: #00699a;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold; }
