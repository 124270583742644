._365m6_qz1Y39m2QGqNTIFB {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center; }

._1RXUBgbsRVOTY8aoByCoha {
  text-transform: uppercase;
  font-size: 0.875rem; }

._1AxamMpSAqjf7ongRMbLIj {
  flex: 1; }

._1rJCb99KYefyJHwerUA3nl {
  text-align: right;
  padding-right: 15px;
  font-weight: bold; }

._2xMRzZeeQdAAQZ3CHZ-bzx {
  margin-left: 15px; }

._1efHXDJ1-JecNlZMTxgM37 {
  display: inline-block;
  width: 200px;
  position: relative;
  z-index: 200; }

._2ALSmDA0rKOKY-Oqn6ixj4 {
  background-color: #fff;
  padding: 10px 10px;
  border-style: solid;
  border-color: #000;
  border-width: thin;
  font-size: 0.8rem;
  height: 43px; }

._2ALSmDA0rKOKY-Oqn6ixj4::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 16px;
  border: 7px solid transparent;
  border-color: #394a6d transparent transparent transparent; }

._2ALSmDA0rKOKY-Oqn6ixj4._21DvDbpTBabRUaVXPJDp2Y::after {
  top: 8px;
  border-color: transparent transparent #394a6d transparent; }

._3czutGSkDu-WBz55vpjyiN {
  margin: 0;
  padding: 0;
  border: thin solid #000;
  position: absolute;
  width: 100%; }
  ._3czutGSkDu-WBz55vpjyiN li {
    list-style-type: none;
    padding: 10px 10px;
    background: #fff;
    cursor: pointer;
    font-size: 0.9rem; }
  ._3czutGSkDu-WBz55vpjyiN li:hover {
    background-color: #3b3b3b;
    color: #ffffff; }

@media (max-width: 500px) {
  ._1rJCb99KYefyJHwerUA3nl {
    text-align: left;
    font-size: 0.8rem;
    padding-right: 0;
    position: absolute; }
  ._1efHXDJ1-JecNlZMTxgM37 {
    width: 47%; } }

@media (max-width: 767px) {
  ._2z-yQWiPGwgflASkriqSd8 {
    display: block;
    background-color: #fff;
    padding: 10px 10px;
    border-style: solid;
    border-color: #000;
    border-width: thin;
    font-size: 0.9rem;
    height: 100%;
    width: 155px; }
  .IEmih6MBzIb4Yzl2p9Nf_ {
    display: none; } }
