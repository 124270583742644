._117T7IUENSL9cuNkfiMYQR {
  text-align: center;
  margin: 30px 0; }

._3zH1htWmtMQbYdphM9Sfj5 {
  padding: 10px;
  padding-left: 45px;
  padding-right: 45px;
  background-color: white;
  display: inline-block;
  border: 1px solid;
  cursor: pointer; }

._19UxmYTm4MSrkwvse_u2fl {
  height: 271px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 15px;
  display: flex; }
  ._19UxmYTm4MSrkwvse_u2fl ._2vxjaQNiQdj_fpXdFQ_5Kw {
    color: #ffffff;
    font-weight: bold;
    align-self: center;
    margin: 0 auto;
    text-align: center; }

._2tT97JoB_P0nSUCLfn0PcD {
  font-weight: bold;
  display: block;
  font-size: 1.2rem; }

._3vyYDs5HHuzFFKoWqqpOuf {
  font-weight: bold;
  padding-bottom: 1rem;
  display: none;
  font-size: 1.2rem; }

@media (max-width: 500px) {
  ._2tT97JoB_P0nSUCLfn0PcD {
    display: none; }
  ._3vyYDs5HHuzFFKoWqqpOuf {
    display: block; } }

._1mChvDk61CqXVGqpFfCco4 {
  display: none; }

@media (max-width: 500px) {
  ._1mChvDk61CqXVGqpFfCco4 {
    display: block;
    margin-top: 12.5px; } }
