._3_pLoOp-NgBmSlMET84Pnt {
  display: flex; }

._3iQR5vrXJOmIV87C_LpYgE {
  width: 600px;
  height: 470px; }

._3yhAEPepfUNwiBUXTeG-HL {
  font-size: 20px;
  font-weight: 600;
  overflow: hidden; }

._3HBCVzF2iHcwehmBzyXpxZ {
  width: 330px;
  height: 470px; }
