._3gTAQ0HjmsQkJ2ZzP69EWV {
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 5000;
  min-width: 100%;
  min-height: 100%; }

.AaaqakRZhFW86ElAP_-js {
  position: fixed;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  z-index: 5001;
  transition: 0.3s all;
  background: white; }
  .AaaqakRZhFW86ElAP_-js .Fqgaj6ghMZrARN7mag43y {
    background: white;
    position: relative; }
    .AaaqakRZhFW86ElAP_-js .Fqgaj6ghMZrARN7mag43y .jz3jeu3Kke61QUcWw04r- {
      padding: 15px;
      min-height: 50px;
      background: #eee; }
      .AaaqakRZhFW86ElAP_-js .Fqgaj6ghMZrARN7mag43y .jz3jeu3Kke61QUcWw04r- ._1_bAXUYbm3uC6d2aH3MWZZ {
        float: right;
        cursor: pointer;
        width: 15px; }
      .AaaqakRZhFW86ElAP_-js .Fqgaj6ghMZrARN7mag43y .jz3jeu3Kke61QUcWw04r- ._2H2s4nS1PGRl7QZ3Ntzeot {
        color: black; }
    .AaaqakRZhFW86ElAP_-js .Fqgaj6ghMZrARN7mag43y .xswGNVBPT141uOVQScZZ3 {
      padding: 15px; }

._16m-NmfwCREiQhcaK-ZG1Y {
  display: none; }

._236C8Tvfr98zAhJGBe2O2B {
  display: block; }

._1628CUQPQb7ReGIsYYYlcH {
  display: flex;
  align-items: center; }

@media (max-width: 768px) {
  .AaaqakRZhFW86ElAP_-js {
    width: 100% !important;
    padding: 15px; } }

._2_9tx8m4jsT7tdhGpCgBs6 {
  visibility: hidden; }
