._3AEd6nkfX4B387R5FXIeeP {
  position: relative;
  border: 1px solid #eee;
  cursor: pointer; }

.u1QvFH_f4-puywiF6pcza {
  height: 250px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat; }

._3L0t_VERYKQNb4x_wfz5Ht {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  transition: .5s all;
  opacity: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  ._3L0t_VERYKQNb4x_wfz5Ht:hover {
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1; }
  ._3L0t_VERYKQNb4x_wfz5Ht i {
    font-size: 2.1875rem; }

._1IuVyyBKTMn4_ZQW6kRCCw {
  width: 100%;
  background: white; }

._2yOaVJiADqbfhC2BtvVzbK {
  position: absolute;
  width: 70%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid white;
  padding: 15px; }

._18g04PscmsDdr4DmUp8kZY {
  color: white !important; }
