._3ZnaYAZH2zbxLEDGWJ6PBr {
  margin: 0;
  margin-bottom: 15px;
  color: #fc5d20; }

._2lvh_cm5eAXw3_g7orRyib {
  padding: 15px;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 30px;
  margin-bottom: 30px; }
  ._2lvh_cm5eAXw3_g7orRyib:hover {
    background-color: #eee; }

._3-rydpOL2CD6Feeoyn9Bgi {
  clear: both;
  text-align: center;
  margin-top: 30px; }

._2mGT8ypjHYhNHbMVi5FR90 {
  margin: 0 auto 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; }

@media screen and (max-width: 991px) {
  ._2mGT8ypjHYhNHbMVi5FR90 {
    gap: 15px;
    margin-left: -15px;
    margin-right: -30px; } }

._3F0tv_ruDL5nuz-nrlNlGo {
  flex: 1 0 33.33%;
  min-width: 260px; }

._15ryYE2CVjvphXdaBdeOwy {
  display: none; }
