._2U1EBtbnuDYP3loLWet1OV {
  font-size: 2rem; }

._3UPwjBNsIZ3FD64GFBoxz {
  background-color: #eeeeee;
  font-size: 0.9rem;
  padding: 10px; }

._1nDT4UzC9cGdnp0fl9QJZF {
  width: 16px; }

._2TSRGpq86MXGGBuyFIwchN {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.9rem; }

@media (max-width: 768px) {
  ._3UPwjBNsIZ3FD64GFBoxz {
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 0.8rem; }
  ._2TSRGpq86MXGGBuyFIwchN {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.8rem; } }

._2lvhwpUXEM_i1lLnggR_pi {
  font-size: 0.8em;
  margin-bottom: -15px; }
  ._2lvhwpUXEM_i1lLnggR_pi a {
    cursor: pointer; }

.J9QnftVWG9aSmUJtQjcwE {
  font-size: 0.8em;
  max-width: 300px;
  display: flex; }

a {
  color: #005983; }

._2UmsW6d2sVjyuE2UXnCPnx {
  position: relative;
  display: inline-block; }

._2UmsW6d2sVjyuE2UXnCPnx ._3nWeiI-CRYf6EyZpCNSYPU,
._3e33Zj0HiOS-hlntFC7tcO ._3nWeiI-CRYf6EyZpCNSYPU {
  width: 300px;
  background-color: black;
  text-align: left;
  padding: 0.5em;
  background-color: #f4f4f4;
  line-height: 1.1rem;
  font-size: 0.8rem;
  position: absolute;
  left: 0;
  z-index: 1; }

._2UmsW6d2sVjyuE2UXnCPnx ._3nWeiI-CRYf6EyZpCNSYPU {
  visibility: hidden;
  top: 100%; }

._2UmsW6d2sVjyuE2UXnCPnx:hover ._3nWeiI-CRYf6EyZpCNSYPU {
  visibility: visible; }

._3e33Zj0HiOS-hlntFC7tcO {
  display: none; }

._3Lcq8lba-n3vgrZjvE75DU {
  display: flex;
  align-items: center; }

.d-vg6aSsk4if1n7iSARdu {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #636363;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer; }

._3PkVdm3c87zJdqd94lmlo5 {
  cursor: pointer; }

._3Clfcthn32TC857c_pE36U {
  margin-left: 5px; }

._3UlrLan1ZciL0lQIuzycBX {
  margin-top: 10px;
  margin-bottom: 10px; }

._2RW9VKes19YuxqSXfQ6jyu ._35-niyEtCXkJ64jiWCRj-o {
  line-height: 1.7rem; }

._10nbMmPco3ylzMCouEAwwr {
  font-weight: bold;
  padding-bottom: 1rem; }

._1j-wLxTpBrlISxOBaVUfFJ {
  font-size: 1.2rem; }

._1mWp_CwLg1Xiri5k-jEzpJ {
  display: flex;
  flex-direction: row; }

._1mWp_CwLg1Xiri5k-jEzpJ,
._1VlZISvLqpfGbzOZiS4umN {
  padding-bottom: 25px; }

.JuFP93HUYULfLAket1v4B {
  column-count: 2; }

._1ya31cIatMtUNEHSSNVUlw {
  font-weight: bold;
  text-decoration: underline; }

@media (max-width: 500px) {
  ._2UmsW6d2sVjyuE2UXnCPnx {
    display: none; }
  ._3e33Zj0HiOS-hlntFC7tcO {
    display: inline-block; }
  .JuFP93HUYULfLAket1v4B {
    column-count: 1; } }

._1L9iTbw2k9qYhWyHIgK5fi {
  position: relative;
  height: 92px;
  width: 100%;
  background-color: lightgray;
  border-radius: 5px; }
  ._1L9iTbw2k9qYhWyHIgK5fi._3q51EhzmCnGKfvitOVzz6R {
    margin: 5px 0px;
    height: 50px;
    width: 70%; }
  ._1L9iTbw2k9qYhWyHIgK5fi._3CmXc8FBB8qcdF09xLU6vN {
    margin: 5px 0px;
    height: 20px;
    width: 80%; }
  ._1L9iTbw2k9qYhWyHIgK5fi ._3t7jCo6BloAqmirZgStaz- {
    height: 100%;
    width: 100%;
    position: absolute;
    left: -45%;
    background-image: linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    background-image: -moz-linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    background-image: -webkit-linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    animation: _1L9iTbw2k9qYhWyHIgK5fi 1s infinite; }

@keyframes _1L9iTbw2k9qYhWyHIgK5fi {
  0% {
    left: -25%; }
  100% {
    left: 100%; } }

._3jqrYrHCBOhW-qGOI7icUh {
  margin-top: 10px; }

.lv7LEse3TfqPw1xVKXTDj {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px; }
