._2AdKYPW92Equ8ygMqp407G {
  line-height: 0.875rem;
  min-height: 0.875rem;
  padding-left: 10px;
  padding-bottom: 15px;
  padding-top: 5px;
  text-align: left; }
  ._2AdKYPW92Equ8ygMqp407G .yll66xP5qRIsoy4oeKCG3 {
    color: #0074b3;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem; }
    ._2AdKYPW92Equ8ygMqp407G .yll66xP5qRIsoy4oeKCG3:hover {
      opacity: 0.8; }

@media (min-width: 768px) {
  ._2AdKYPW92Equ8ygMqp407G {
    padding-top: 0px; } }

@media screen and (max-width: 991px) {
  ._2AdKYPW92Equ8ygMqp407G {
    padding-bottom: 18px;
    padding-left: 0px;
    padding-top: 10px; } }
