._2uEeR6TNrH2sEzGxQE7C4g {
  border-radius: 50%;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.9rem;
  margin-left: 0.2rem;
  margin-top: 0.1rem;
  cursor: pointer;
  position: relative; }

.c_TUtrQobgsB_CxMr0fPg {
  border-radius: 50%;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.9rem;
  margin-left: 0.2rem;
  margin-top: 0.1rem;
  cursor: pointer;
  position: relative;
  background: linear-gradient(to right, black 0%, black 50%, white 50%, white 100%); }

.QEOWDx5QzLHqkNNw6HkVY {
  border-radius: 50%;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.9rem;
  margin-left: 0.2rem;
  margin-top: 0.1rem;
  cursor: pointer;
  position: relative;
  background: conic-gradient(red 0deg, orange 60deg, yellow 120deg, green 180deg, blue 240deg, purple 300deg, red 360deg); }

._2uEeR6TNrH2sEzGxQE7C4g._1HXL0gPOyXeLJYUrgoLJsH,
._2uEeR6TNrH2sEzGxQE7C4g:hover {
  border: 3px solid; }

.c_TUtrQobgsB_CxMr0fPg._1HXL0gPOyXeLJYUrgoLJsH,
.c_TUtrQobgsB_CxMr0fPg:hover {
  border: 3px solid; }

.QEOWDx5QzLHqkNNw6HkVY._1HXL0gPOyXeLJYUrgoLJsH,
.QEOWDx5QzLHqkNNw6HkVY:hover {
  border: 3px solid; }

._3n81S_pWz1A1rBOFYfFSCS {
  box-shadow: 0 0 0 1px #d5d5d5 inset; }
