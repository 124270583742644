._3CkGc2s6zjz0XPMEolo9TW {
  line-height: 22px;
  line-height: 25px; }

._1w18b06GzOHsGQx9BCfck8 {
  display: flex; }

.km1Bz9Mkv02vQAgE7CFjy {
  font-size: 18px;
  color: #007ea8 !important; }

._3LJGbAPqBB41kDbS0IvR-U {
  font-size: 18px;
  color: black !important; }

._1Q4jddxCSMcQ7ariihOJWS {
  text-decoration: line-through;
  color: #6e6e6e !important;
  font-size: 18px; }

._2s6u81ybHyT-DiK55-KQdA {
  color: #6e6e6e !important;
  font-size: 18px; }

@media screen and (max-width: 991px) {
  ._3CkGc2s6zjz0XPMEolo9TW {
    margin-bottom: -6px;
    height: 46px;
    display: flex;
    align-items: end; }
  ._3CkGc2s6zjz0XPMEolo9TW._3iNguh3qoQzpbZzOPNLKHD {
    justify-content: center; }
  .km1Bz9Mkv02vQAgE7CFjy {
    font-size: 16px;
    display: block; }
  ._3LJGbAPqBB41kDbS0IvR-U {
    font-size: 16px;
    display: block; }
  ._2s6u81ybHyT-DiK55-KQdA {
    font-size: 14px; }
  ._1Q4jddxCSMcQ7ariihOJWS {
    font-size: 14px; } }
