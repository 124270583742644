.hSjRU7m5cZYODFDYtbCNO {
  padding-left: 10px; }

.A34cuONYIFs5DyIxMPSUg {
  display: inline-block;
  background: #166e9c;
  color: #fff;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 5px; }
