._3f9rsbLETs3qf8t4woaZo2 {
  position: relative;
  width: 288px;
  height: 415px;
  box-shadow: 0.5px 0.2px 5px #c7c6c6; }

._3f9rsbLETs3qf8t4woaZo2:hover {
  border: 0.25px solid #007ea8; }

.nl8cG6YC53877Y_iAvn_C {
  margin: 20px 10px 10px;
  cursor: pointer; }
  .nl8cG6YC53877Y_iAvn_C a {
    text-decoration: none !important; }

._3tDPZPH_Ggc5lBmYcH379J {
  color: black;
  font-weight: bold; }

._2b3-jutlIK6bPa28G1RGuU {
  min-height: 60px;
  margin-bottom: 0;
  line-height: 22px;
  font-weight: bold;
  font-size: 16px; }
  ._2b3-jutlIK6bPa28G1RGuU div {
    color: #3d3d3d;
    font-weight: 700; }

@media screen and (max-width: 600px) {
  ._2b3-jutlIK6bPa28G1RGuU {
    min-height: 80px; } }

@media screen and (max-width: 991px) {
  ._2b3-jutlIK6bPa28G1RGuU {
    margin-bottom: 12px;
    font-size: 14px;
    padding: 5px 1px; } }

.jk7OVEE3qaB11kVXCXBUC {
  color: #8c8b8b;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 17px; }

._132PVI_5Nke6D9R9h_pXcS {
  padding: 5px 10px; }

@media screen and (max-width: 991px) {
  ._132PVI_5Nke6D9R9h_pXcS {
    padding: 5px 1px; } }

._13lB1W-aCxgt0eCiv1yKY1 {
  height: 194px; }

@media screen and (max-width: 1024px) {
  ._13lB1W-aCxgt0eCiv1yKY1 {
    height: 165px; } }

@media screen and (max-width: 769px) {
  ._13lB1W-aCxgt0eCiv1yKY1 {
    height: 110px; } }

._aGKsfWIEo77n0M7GsmOp {
  width: 100%;
  min-height: 200px;
  background-size: cover; }

.F_Gyei8w7fb6Lnw4x_15V {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
  padding: 0 20px; }

._3GURd7o0rLVC3IDQcu4NRZ {
  height: 2rem;
  width: 268px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background-color: #f0f0f0;
  border-radius: 6px;
  transition: background-color 0.3s;
  position: absolute;
  bottom: 10px; }

@media (max-width: 767px) {
  ._3f9rsbLETs3qf8t4woaZo2 {
    flex: inherit;
    flex-basis: 47%;
    margin: 10px 0 0;
    box-shadow: 5px 4px 11px #c7c6c6;
    width: 100%;
    min-width: 0;
    height: auto; }
  ._3GURd7o0rLVC3IDQcu4NRZ {
    display: none; } }

@media (max-width: 500px) {
  ._132PVI_5Nke6D9R9h_pXcS {
    padding: 0; } }

._2jD0GCB-b8UQ-creg6UN-Z {
  display: flex;
  margin-top: 10px;
  margin-left: 10px; }
  ._2jD0GCB-b8UQ-creg6UN-Z ._3r0GgcveBhZCa0RfxqAS_Z {
    margin-left: 5px;
    font-size: 16px; }

._1Ecgec3Gt8m6u4jL_MwDDR {
  display: flex;
  flex-direction: row; }

.cPaRy2-PThU9WWRpn-dSa {
  padding-left: 10px; }

@media screen and (max-width: 991px) {
  .cPaRy2-PThU9WWRpn-dSa {
    padding-left: 0px; } }

@media screen and (max-width: 991px) and (max-width: 991px) {
  ._1Ecgec3Gt8m6u4jL_MwDDR {
    flex-direction: column; } }
