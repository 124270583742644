._30JLd1_aQ2fm7mllAG2yHb {
  background-color: rgba(0, 0, 0, 0.6);
  left: 0%;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0%;
  z-index: 5000; }

.BNukvoceEw7UkMCv-M-I6 {
  background: white;
  left: 50%;
  min-height: 200px;
  position: fixed;
  top: 0%;
  transform: translateX(-50%);
  transition: 0.3s all;
  width: 500px;
  z-index: 5000000000;
  padding: 15px; }
  .BNukvoceEw7UkMCv-M-I6 ._3dIARdO7IIJ9EiXJax_ZcD {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-bottom: 30px;
    width: 15px; }
  .BNukvoceEw7UkMCv-M-I6 .OCRaQ7h4C-N13FEJ11M9O {
    background: white;
    font-size: 0.8rem;
    margin-top: 30px;
    position: relative; }

._3YqAePlzRusfHzXcMC0dVA {
  display: flex;
  justify-content: space-between;
  width: 50%; }

._3QCm_Pngh9bBhTG_JpN4UF {
  color: #eb0000;
  width: 20px;
  height: 20px; }

._1kmr1uTMTHtVLH_lbIKq4B {
  color: #eb0000;
  font-weight: bold; }

@media (max-width: 768px) {
  .BNukvoceEw7UkMCv-M-I6 {
    width: 100%;
    height: 100%;
    overflow-y: auto; }
  ._3YqAePlzRusfHzXcMC0dVA {
    width: 70%; } }
