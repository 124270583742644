._39yZaLNBlp5TylglhZYJi2 {
  height: 80%;
  overflow-y: auto;
  scrollbar-width: thin;
  margin: 1.6rem 4.5rem 0;
  transition: color 0.3s;
  scrollbar-color: #c1c1c1 transparent; }

.wDedY8v304uK4DB8XJ3jd {
  display: flex;
  align-items: center;
  padding: 0.8rem 2rem 0.8rem 0.3rem;
  color: #686868; }

.wDedY8v304uK4DB8XJ3jd:nth-child(odd) {
  background: #f0f0f0; }

.H-QELNbS5gzz-nbGwTvty {
  font-size: 16px;
  font-weight: 600;
  margin-left: 2.4rem; }

._155pBUgRO0o-coFdkMJeSl {
  color: #606060;
  font-size: 12px;
  margin-left: auto; }

.FCsBFaI3aJZ9gNjJ4gBgd {
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 0 28px 0 5px;
  border-right: 1px solid #333; }

.YmnwSPN6b5S_SxJLvvzn1 {
  font-size: 12px;
  padding: 6px 10px;
  width: 110px;
  border: 1px solid #333;
  box-shadow: none;
  background: transparent;
  border-radius: 15px;
  font-weight: 400;
  line-height: 1;
  color: #333;
  cursor: pointer; }

._3JIQ9zHU9lJfxda-H1NrwH {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem; }

._2AcXMX1qJwvkAvpztwXKbG {
  font-size: 16px;
  text-align: center; }

._3RPEDUDhQbTawzzlNQDpnM {
  width: 21px;
  margin-right: 5px; }

._3RPEDUDhQbTawzzlNQDpnM._3DDrujK9TAtRBu2egILdA_ {
  width: 11px; }

@media (max-width: 768px) {
  ._39yZaLNBlp5TylglhZYJi2 {
    font-size: 11px;
    margin: 1.6rem 0 0; }
  .H-QELNbS5gzz-nbGwTvty {
    font-size: 11px;
    margin-left: 10px; }
  .FCsBFaI3aJZ9gNjJ4gBgd {
    align-items: center;
    font-size: 11px;
    margin-left: 12px; }
  .wDedY8v304uK4DB8XJ3jd {
    font-size: 11px;
    padding: 0.8rem 0.3rem; }
  .YmnwSPN6b5S_SxJLvvzn1 {
    font-size: 11px; }
  ._155pBUgRO0o-coFdkMJeSl {
    font-size: 11px; } }
