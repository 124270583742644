._3QAz_YgMBTVX4TfgaEnXFX {
  font-weight: bold;
  text-align: center;
  font-size: 1.7rem;
  margin-top: 3rem;
  margin-bottom: 3rem; }

._1bB_7GOROSJIq4_MnpqvNS {
  display: flex;
  flex-wrap: wrap; }

._199phvQyZcYONeyMQ-wMRi {
  position: relative;
  margin-bottom: 1rem;
  width: 25%;
  min-width: 260px; }

.-u3usMTVNw8duCpG8XYT9 {
  margin: 10px;
  cursor: pointer; }
  .-u3usMTVNw8duCpG8XYT9 img {
    width: 100%;
    height: auto; }

._3_ykIz6yC3Ms7tKZwNlfMj {
  min-height: 60px;
  margin-bottom: 10px;
  line-height: 22px;
  font-weight: bold;
  font-size: 16px; }

._35LGzNz8Imn0_LqDbzpCQn {
  margin-top: 15px;
  line-height: 22px;
  font-weight: bold;
  font-size: 14px;
  color: black; }

.vdi_9p0cvo0Tf-cGCID-p {
  padding: 5px 10px; }

._2bCanoevwvJOTt4DV7QioZ {
  width: 100%;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }

@media (max-width: 1124px) {
  ._199phvQyZcYONeyMQ-wMRi {
    flex: 1 0 50%;
    min-width: auto;
    max-width: 50%; }
  .vdi_9p0cvo0Tf-cGCID-p {
    padding: 5px; } }

._45UFN967t8Xt4TU4Hjkwy {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px; }

._3vly_U63WDa-8ierJpLseU {
  padding-left: 10px; }

@media screen and (max-width: 991px) {
  ._3vly_U63WDa-8ierJpLseU {
    padding-left: 0px; } }
